export const stagingUrl = (() => {
	return process.env.REACT_APP_TYPE == "preprod"
		? process.env.REACT_APP_PREPROD_URL
		: process.env.REACT_APP_STAGING_URL;
})();

export const API = {
	AUTH_TOKEN: "aeternalLoversToken",

	// -------------------PATH URLS---------------------

	// -------------------AUTH APIs---------------------
	LOGIN: stagingUrl + "user/login",
	REGISTER: stagingUrl + "user/signup",
	FORGOT_PASSWORD: stagingUrl + "user/forgotPassword",
	RESET_PASSWORD: stagingUrl + "user/resetPassword",
	SET_PASSWORD: stagingUrl + "user/setPassword",

	// -------------------------------------------------
	// ----------------------SHOP-----------------------
	GET_CART_ITEMS: stagingUrl + "shop/getCartItems",
	GET_ALL_ARTS_TYPE: stagingUrl + "shop/getAllArtsType",
	GET_ALL_PRODUCTS: stagingUrl + "shop/getAllProducts",
	GET_PRODUCT_DETAILS: stagingUrl + "shop/getProductDetails",
	WRITE_REVIEW: stagingUrl + "shop/writeReview",
	ADD_TO_CART: stagingUrl + "shop/addTOCart",
	REMOVE_FROM_CART: stagingUrl + "shop/removeFromCart",
	UPDATE_CART_ITEM_QUANTITY: stagingUrl + "shop/updateQty",
	GET_PAYMENT_INTENT: stagingUrl + "shop/getPaymentIntent",
	CREATE_ORDER: stagingUrl + "shop/createOrder",
	SEARCH_PRODUCTS: stagingUrl + "shop/searchProduct",
	GET_ALL_CUSTOM_PRODUCTS: stagingUrl + "shop/getAllCustomProduct",
	CHECK_PROMO_CODE: stagingUrl + "shop/checkPromoCode",
	// ----------------------------------------------------
	// ----------------------USER---------------------------
	ADD_ADDRESS: stagingUrl + "user/addAddress",
	ADD_ART_TYPE: stagingUrl + "user/addArtType",
	DELETE_ART_TYPE: stagingUrl + "user/deleteArtType",
	EDIT_ART_TYPE: stagingUrl + "user/editArtType",
	GET_ART_TYPE_LIST: stagingUrl + "user/getArtTypeDetails",
	DELETE_ADDRESS: stagingUrl + "user/deleteAddress",
	EDIT_ADDRESS: stagingUrl + "user/editAddress",
	CHANGE_NAME: stagingUrl + "user/editName",
	CHANGE_PASSWORD: stagingUrl + "user/changePassword",
	CHANGE_PROFILE_PICTURE: stagingUrl + "user/changeProfilePic",
	GET_ALL_ORDERS: stagingUrl + "user/getAllOrders",
	// -----------------------------------------------------
	// -----------------MISC-------------------------------
	ADD_MEDIA: stagingUrl + "user/addMedia",
	INITIALISE_MULTIPART: stagingUrl + "music/initializeMultipart",
	FINALISE_MULTIPART: stagingUrl + "music/finalizeMultipart",
	SUBSCRIBE_NEWSLETTER: stagingUrl + "user/subscribed",
	// ----------------------------------------------------

	// ----------------MUSIC-------------------------------
	COLLAB_REQ: stagingUrl + "music/collaboratorRequest",
	INITIALISE_MULTIPART: stagingUrl + "music/initializeMultipart",
	FINALISE_MULTIPART: stagingUrl + "music/finalizeMultipart",
	LATEST_MUSIC: stagingUrl + "music/getLatestMusic",
	MUSIC_BY_ID: stagingUrl + "music/getMusicById",
	MUSIC_BY_ARTIST: stagingUrl + "music/getMusicByArtist",
	MUSIC_BY_ALL: stagingUrl + "music/getAllMusic",
	SEARCH_MUSIC: stagingUrl + "music/searchMusic",
	// -------------------------------------------------

	// ----------------CALENDAR-----------------------------
	REQUEST_EVENT: stagingUrl + "event/requestEvent",
	GET_ALL_EVENTS: stagingUrl + "event/getAllEvent",
	// -------------------------------------------------

	// ----------------COLLABORATORS-----------------------------
	GET_COLLABORATORS: stagingUrl + "user/getAllCollaborator",
	COLLABORATOR_DETAILS: stagingUrl + "user/getCollaboratorById",
	// -------------------------------------------------------

	// -----------------CONTACT US--------------------------------
	CONTACT_US: stagingUrl + "user/contactUs",
};
